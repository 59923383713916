@import "scss/base.scss";

$side-nav-height: 3rem;

.interfaceNavLink {
    height: $side-nav-height;
    display: flex;
    align-items: center;
    text-decoration: $hide;
    color: $color-text;
    margin-bottom: $margin-10;

    &:hover {
        background-color: $color-background-hover;
    }
}

.interfaceNavLinkActive {
    border-right: solid 4px $color-blue;
    color: $color-blue;

    .linkIcon path {
        fill: $color-blue;
    }
}

.linkIcon {
    display: flex;
}